<template>
    <v-data-table
        :headers="headers"
        :items="reviews"
        class="elevation-1"
        hide-default-footer
        disable-pagination
    >
        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>Отзывы</v-toolbar-title>
                <v-spacer />
                <v-btn color="primary" @click="create">
                    <v-icon>mdi-plus</v-icon>Добавить</v-btn>
            </v-toolbar>
        </template>
        <template v-slot:[`item.createdAt`]="{ item }">
            {{ item.createdAt | moment('DD.MM.YYYY HH:mm') }}
        </template>
        <template v-slot:[`item.subject`]="{ item }">
            {{ SUBJECT_TITLE[item.subject] }}
        </template>
        <template v-slot:[`item.position`]="{ item }">
            <v-row class="ma-0 pa-0">
                <v-col cols="9" class="pa-0">
                    <v-text-field
                        v-model="item.position"
                        class="pa-0 ma-0"
                        hide-details
                    />
                </v-col>
                <v-col class="pa-0 d-flex justify-center align-center">
                    <v-icon 
                        small
                        color="primary" 
                        @click="setPosition(item._id, item.position)"
                    >mdi-check</v-icon>
                </v-col>
            </v-row>
        </template>
        <template v-slot:[`item.enabled`]="{ item }">
            <v-icon v-if="item.enabled" color="green">mdi-check</v-icon>
            <v-icon v-else color="red">mdi-close</v-icon>
        </template>
        <template v-slot:[`item.edit`]="{ item }">
            <v-icon color="primary" @click="edit(item._id)">mdi-pencil</v-icon>
        </template>
    </v-data-table>
</template>

<script>
    import store from '../../store';
    import { mapState } from 'vuex';

    import { SUBJECT_TITLE } from '../../vars';

    export default {
        name: 'ReviewsList',
        components: {
        },
        data: () => ({
            SUBJECT_TITLE,
            headers: [
                { text: 'Имя', value: 'name' },
                { text: 'Позиция в списке', value: 'position' },
                { text: 'Предмет', value: 'subject' },
                { text: 'Активен', value: 'enabled' },
                { text: '', align: 'right', value: 'edit' },
            ],
        }),
        computed: {
            ...mapState('reviews', {
                reviews: state => state.entities
            }),
        },
        async mounted() {
            this.fetch();
        },
        methods: {
            async fetch() {
                await store.dispatch('reviews/fetch', {});
            },
            async create() {
                await this.$router.push({ name: 'reviews-create' });
            },
            async edit(id) {
                await this.$router.push({ name: 'reviews-edit', params: { id } });
            },
            async setPosition(id, position) {
                await store.dispatch('reviews/setPosition', { id, position });
                await this.fetch();
            }
        }
    }
</script>